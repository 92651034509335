import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import courieroLogo from '../Assets/couriero-logo.png'
import { Helmet } from 'react-helmet-async'

const Navbar = () => {
  const [xmarkVisibility, setXmarkVisibility] = useState('hidden')
  const [barsVisibility, setBarsVisibility] = useState('block')
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showmenu, setshowmenu] = useState(false);




  const toggle = () => {
    if (barsVisibility === 'block') {
      setBarsVisibility('hidden')
      setXmarkVisibility('block')
    } else {
      setBarsVisibility('block')
      setXmarkVisibility('hidden')
    }
  }

  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
  };


  const Menu = () => {
    setshowmenu(!showmenu);
  }


  return (
    <React.Fragment>
      <Helmet>
        <title>Couriero - Reliable Courier Services</title>
        <meta name="description" content="Track your shipment, calculate shipping rates, and explore our features." />
      </Helmet>
      <div className='fixed inset-x-0 top-0 left-0 py-2 bg-white bg-opacity-40 backdrop-blur-sm   z-50 '>
        <div className='flex flex-row justify-around my-2 items-center'>
          <div className='flex flex-row items-center'>
            <div className='lg:hidden mx-2'>
              <i className={`fa-solid fa-bars ${barsVisibility}`} onClick={toggle}></i>
              <i className={`fa-solid fa-xmark ${xmarkVisibility}`} onClick={toggle}></i>
              <div className={`${xmarkVisibility} absolute top-16 left-5 rounded-2xl w-56 bg-gradient-to-r from-orange-200 to-blue-500`} >
                <ul className='flex flex-col justify-between bg-white m-1 rounded-2xl'>
                  <Link to='/home-new' aria-label="Go to Home Page">
                    <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500 cursor-pointer'>
                      HOME
                      <span className='absolute right-5'>
                        <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </Link>

                  <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500 cursor-pointer' aria-label="Explore our Products" onClick={Menu}>
                    PRODUCT
                    <span className='absolute right-5'>
                      <i className="fa-solid fa-arrow-down animate-bounce" aria-hidden="true"></i>
                    </span>
                    {showmenu && (
                      <ul className='ml-4'>
                        <li>
                          <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer" className='flex items-center pt-4 text-sm hover:bg-gray-100'>
                            <span className="text-gray-600 text-xs w-11/12 transition duration-300">E-commerce shipping</span>
                            <i className="fa-solid w-1/12 fa-arrow-right" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <Link to='/track-shipment' className='flex items-center pt-4 text-sm hover:bg-gray-100' title="Track your Shipment"
                            aria-label="Track your Shipment">
                            <span className="text-gray-600 text-xs w-11/12 cursor-pointer transition duration-300">Track Shipment</span>
                            <i className="fa-solid w-1/12 fa-arrow-right" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/shipping-rate-calculator" className='flex items-center pr-2 pt-3 text-sm hover:bg-gray-100'
                            title="Use Shipping Rate Calculator"
                            aria-label="Use Shipping Rate Calculator">
                            <span className="text-gray-600 cursor-pointer text-xs transition duration-300">Shipping rate calculator</span>
                            <i className="fa-solid w-1/12 fa-arrow-right" aria-hidden="true"></i>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>

                  <Link to='/features' aria-label="Learn about Couriero features"
                    title="View Features">
                    <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500 cursor-pointer'>
                      FEATURES
                      <span className='absolute right-5'>
                        <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </Link>

                  <Link to='/blogs' aria-label="Read Blogs" title="Read Blogs">
                    <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500 cursor-pointer'>
                      Blogs
                      <span className='absolute right-5'>
                        <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </Link>

                  <Link to='/track-shipment' aria-label="Track your Shipment" title="Track Shipment">
                    <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider border-b-2 border-blue-500 cursor-pointer'>
                      TRACK SHIPMENT
                      <span className='absolute right-5'>
                        <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </Link>

                  <Link to="/price-list" aria-label="Check Pricing" title="Check Pricing">
                    <li className='relative mx-4 py-4 text-gray-700 font-bold text-sm tracking-wider cursor-pointer'>
                      PRICING
                      <span className='absolute right-5'>
                        <i className="fa-solid fa-arrow-right" aria-hidden="true"></i>
                      </span>
                    </li>
                  </Link>
                </ul>

              </div>
            </div>
            <div className='mx-2'>
              <Link to='/'>
                <img
                  src={courieroLogo}
                  className='lg:h-9 blur-none lg:w-48 h-7 w-36'
                  alt="Couriero Logo - Reliable Courier Aggregator"
                  title="Couriero - A Reliable Courier Service Aggregator"
                  loading='lazy'
                />
              </Link>
            </div>

          </div>
          <div className='hidden lg:block mx-1'>
            <ul className='flex flex-row justify-between'>
              <Link to='/' aria-label="Go to Couriero Home">
                <div className="relative group">
                  <li className="mx-1 px-2 text-gray-600 hover:text-blue-900 font-bold text-sm tracking-wider">
                    HOME
                  </li>
                  {/* Sliding underline */}
                  <span className="absolute bottom-[-2px] left-0 w-full h-[2px] bg-blue-900 rounded-full scale-x-0 transition-transform duration-300 ease-in-out origin-left group-hover:scale-x-100"></span>
                </div>
              </Link>
              <li className='mx-1 px-2 cursor-pointer hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider' onMouseEnter={toggleSubmenu} onMouseLeave={toggleSubmenu}>
                PRODUCTS
                {showSubmenu && (
                  <ul className="absolute py-2 bg-white flex flex-col border border-gray-300 rounded-lg">
                    <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                      <li className='px-2 items-center border-b py-3 text-sm flex flex-row hover:bg-gray-100'>
                        <i className="fa-solid px-2 text-blue-600 fa-truck-fast"></i>
                        E-commerce shipping
                      </li>
                    </a>
                    <Link to='/track-shipment' aria-label="Track your shipment">
                      <li className='px-2 py-3 border-b items-center text-sm flex flex-row hover:bg-gray-100'>
                        <i className="fa-solid text-blue-600 px-2 fa-map-location"></i>
                        Track Shipment
                      </li>
                    </Link>
                    <Link to="/shipping-rate-calculator" aria-label="Calculate your shipping rates">
                      <li className='px-2 py-3 items-center text-sm flex flex-row hover:bg-gray-100'>
                        <i className="fa-solid px-2 text-blue-600 fa-calculator"></i>
                        Shipping rate calculator
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
              <Link to='/features' aria-label="Discover Couriero Features">
                <li className='mx-1 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>FEATURES</li>
              </Link>
              <Link to='/blogs' aria-label="Read Couriero Blogs">
                <li className='mx-1 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>BLOGS</li>
              </Link>
              <Link to='/track-shipment' aria-label="Track your shipment">
                <li className='mx-1 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>TRACK SHIPMENT</li>
              </Link>
              <Link to='/price-list' aria-label="View Couriero Pricing">
                <li className='mx-1 px-2 hover:text-blue-900 text-gray-600 font-bold text-sm tracking-wider'>PRICING</li>
              </Link>
            </ul>
          </div>

          <div className='flex flex-row items-center mx-2'>
            <a href='http://app.couriero.in/login' target='_blank' rel="noopener noreferrer" aria-label="Login to your account">
              <button className='mr-2 rounded-full bg-white shadow-2xl py-1 px-5 text-blue-900 text-base font-bold border border-blue-900 duration-300 hover:text-white hover:bg-blue-900 hover:shadow-lg hover:border-white'>
                Login
              </button>
            </a>
            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer" aria-label="Sign up for a new account">
              <button className='ml-2 rounded-full bg-blue-900 shadow-2xl py-1 px-5 text-white text-base font-bold lg:block hidden hover:text-blue-900 duration-300 hover:shadow-lg hover:bg-white hover:border hover:border-blue-800'>
                Get Started for Free
              </button>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
