import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import courieroLogo from '../Assets/couriero-logo.png'


const Footer = () => {
  const emailAddress = 'support@couriero.in';
  const city = "mumbai"

  const paths = [
    "/best-delivery-services" ,
    "/top-courier-services" ,
    "/lowest-shipping-rates" ,
    "/courier-services-near-me"
  ]

  const handleLinkClick = () => {
    const randomPath = paths[Math.floor(Math.random() * paths.length)];
    window.location.href = randomPath; // Redirect to the selected path
  };


  const [newsLetterEmail, setNewsLetterEmail] = useState('')

  const newsLetterEmailOnChange = (e) => {
    setNewsLetterEmail(e.target.value)
  }

  return (
    
    <div className=' lg:py-10  text-slate-700 flex md:flex-row flex-col sticky bottom-0 z-0  bg-slate-200 px-10 justify-between'>
      <div className='md:w-2/5 w-full flex flex-col items-start'>
        <img src={courieroLogo} alt='logo' className='my-4 ml-2 w-2/4'  title='Couriero Logo'  />
        <h3 className='text-lg pl-1 font-semibold'>© 2024 Soft-Innov Technologies Pvt. Ltd.</h3>
        <h3 className='text-lg pl-1 font-semibold'>All Rights Reserved</h3>
        <div className='flex flex-row my-5 items-center' aria-label='Social Media Links'>
          <div className='flex'>
          <a href='https://twitter.com/CourieroIN' className='mr-2 bg-gray-700 cursor-pointer text-lg py-1 px-2 rounded-full' target='_blank' rel="noopener noreferrer" title='Follow us on Twitter'>
            <i className="fa-brands fa-x-twitter text-white"></i>
          </a>
          </div>
          <div className='flex'>
          <a href='https://www.facebook.com/couriero.in' className='mx-2 cursor-pointer text-lg py-1 px-3 rounded-full bg-gray-700' target='_blank' rel="noopener noreferrer" title='Like us on Facebook'>
            <i className="fa-brands fa-facebook-f text-white "></i>
          </a>
          </div>
          <div className='flex'>
          <a href='https://www.instagram.com/couriero.in/' className='mx-2 cursor-pointer text-xl py-1 px-2 rounded-full bg-gray-700' target='_blank' rel="noopener noreferrer"  title='Follow us on Instagram'
          >
            <i className="fa-brands fa-instagram text-white"></i>
          </a>
          </div>
          <div className='flex'>
          <a href='https://www.youtube.com/channel/UC2nLLn3C2NqBN8toLZzaX4w' className='mx-2 cursor-pointer text-lg py-1 px-2 rounded-full bg-gray-700' target='_blank' rel="noopener noreferrer"       title='Watch our videos on YouTube'   >
            <i className="fa-brands fa-youtube text-white "></i>
          </a>
          </div>
          <div className='flex'>
             <a href='https://www.linkedin.com/company/couriero' className='mx-2 cursor-pointer text-lg py-1 px-2 rounded-full bg-gray-700' target='_blank' rel="noopener noreferrer"  title='Connect with us on LinkedIn'>
            <i className="fa-brands fa-linkedin-in text-white"></i>
          </a>
          </div>
        </div>
      </div>

      <div className='md:w-3/5 w-full flex md:flex-row flex-col md:py-4' >
        <div className='md:w-3/4   w-full mr-5 flex flex-col items-start'>
          <h2 className='font-bold  text-xl'>Get In Touch</h2>
          <div className='flex flex-row items-center my-2 '>
            <i className="fa-solid fa-envelope mr-2 "></i>
            <a href={`mailto:${emailAddress}`} className=" hover:underline">support@couriero.in</a>
          </div>
          <div className='flex flex-row  my-2'>
            <i className="fa-solid fa-location-dot mr-2 mt-1 "></i>
            <h3 className='text-start'>6th Floor, Bhamashah Techno Hub, Sansthan Marg, Malviya Nagar, Jaipur  302017</h3>
          </div>
        </div>
        <div className='md:w-2/4 lg:py-0 py-5  w-full  flex flex-col items-start  '>
          <h2 className='font-bold text-xl'>Learn More</h2>
          <div className='flex flex-col mt- space-y-1'>
         <Link to='/about-us' title='About us'>
         <h3 className='hover:underline cursor-pointer'>About Us</h3>
         </Link> 
         <Link to ='/contact-us' title='Contact us'>
          <h3 className='hover:underline cursor-pointer'>Contact Us</h3>
          </Link>
          <h3 className='hover:underline cursor-pointer'>Press Release</h3>
          <h3 className='hover:underline cursor-pointer'>Careers</h3> 
          <Link to='/privacy-policy' title='Privacy Policy'>
          <h3 className='hover:underline cursor-pointer'>Privacy Policy</h3>
          </Link>
          <Link to ='/refund-policy' title='Refund & Cancellation Policy'>
          <h3 className='hover:underline cursor-pointer'>Refund & Cancellation Policy</h3>
          </Link>
          <Link to ='/terms-and-conditions' title='Terms and Conditions'>
          <h3 className='hover:underline cursor-pointer'>Terms and Conditions</h3>
          </Link>
          <Link to = {`/courier-services-in-${city}`} title='Our Coverage'>
          <h3 className='hover:underline cursor-pointer'>Our Coverage</h3>
          </Link>
          <h3 className='hover:underline cursor-pointer' onClick={handleLinkClick}>Popular Services</h3>
          </div>
        </div>
      </div>

      <div className='md:w-1/5  w-full flex flex-col  items-start py-4 md:px-4 ' >
        <h2 className='font-bold text-xl'>Newsletter</h2>
        <div className='flex mt-2 flex-col'>
        <p className='text-start'>Subscribe to our newsletter to get our news & deals delivered to you.</p>
        <div className='flex  flex-row  my-4'>
          <input
            type='email'
            className=' text-sm rounded-l-2xl border border-solid border-gray-300 pl-2 py-1'
            placeholder='Enter Email Id here'
            id='email'
            name='email'
            value={newsLetterEmail}
            onChange={newsLetterEmailOnChange}
          />
          <button className='border border-solid border-blue-500 px-2 rounded-r-2xl bg-blue-500 text-white'>
            <h3 className='font-semibold'>Join</h3>
          </button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
