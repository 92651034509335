import React, { useEffect } from 'react'
import blog from "../Assets/BlogPhotos/STREAMFULL.png"
import doortodoor from "../Assets/BlogPhotos/DORTODORFULL.png"
import banner from "../Assets/BlogPhotos/Blogcollage2.png"
import onlineshipping from "../Assets/BlogPhotos/OnlineShippingFullNew.png"
import Increase from "../Assets/BlogPhotos/Increase.png"
import products from "../Assets/BlogPhotos/products.png"
import { Link } from 'react-router-dom'
import sidebanner from "../Assets/BlogPhotos/sidebanner.png"
import festive from "../Assets/BlogPhotos/festive.png"
import cheapcourier from "../Assets/BlogPhotos/cheapcourier.png"
import minimise from "../Assets/BlogPhotos/minimise.png"
import strategy from "../Assets/BlogPhotos/strategy.png"
import AOS from 'aos';
import { Helmet } from 'react-helmet-async'
import 'aos/dist/aos.css';

const BlogPage = () => {
  const blogs = [
    {
      id: 'Best-ecommerce-shipping-strategy',
      imageUrl: strategy,
      title: "Best E-Commerce Shipping Strategy that will work for your Startup",
      content: 'As more entrepreneurs dive into starting an e-commerce business, creating a solid e-commerce shipping strategy is vital',
      date: 'Oct 14, 2024',
      author: 'Anjali Daga'
    }
    ,
    {
      id: 'Top-11-cheapest-courier-delivery-service',
      imageUrl: cheapcourier,
      title: "Top 11 Cheapest Courier Delivery Service in 2024",
      content: 'In today’s fast-paced world, finding the cheapest courier service can make a big difference for businesses and individuals alike,',
      date: 'Oct 9, 2024',
      author: 'Bhuvan Ralhan'
    }
    ,
    {
      id: 'How-to-minimize-cod-failures-and-returns',
      imageUrl: minimise,
      title: "How to Minimize COD Failures and Returns?",
      content: 'Cash on Delivery (COD) is a popular payment method in India, especially for customers who are skeptical about paying online',
      date: 'Oct 8, 2024',
      author: 'Sahil chhabra'
    }
    ,
    {
      id: 'Celebrate-the-festive-season-with-couriero',
      imageUrl: festive,
      title: "Celebrate the Festive Season Courier Services with Couriero: Fast, Reliable, and Affordable",
      content: 'The festive season is upon us, and as Diwali 2024 approaches, it’s time to start sending gifts, festive supplies',
      date: 'Sep 6, 2024',
      author: 'Sahil Chhabra'
    }
    ,
    {
      id: 'How-to-increase-online-sales-this-festive-season',
      imageUrl: Increase,
      title: "How to Increase Online Sales This Festive Season ",
      content: 'As the festive season approaches, businesses have a unique opportunity to increase online sales. With consumers eager to shop for gifts and ',
      date: 'Oct 5, 2024',
      author: 'Anjali Jain'
    }
    ,
    {
      id: 'Best-products-to-sell-on-diwali-in-2024',
      imageUrl: products,
      title: "8 Best Products to Sell on Diwali in 2024",
      content: 'Diwali, known as the festival of lights, is one of the most celebrated festivals in India. It’s a time when people come together to exchange gifts',
      date: 'Oct 8, 2024',
      author: 'Samkit Jain'
    }
    ,
    {
      id: 'E-Commerce-logistics-professional-parcel-delivery-services-seamless-solutions',
      imageUrl: blog,
      title: 'Streamlining E-Commerce Logistics: How Our Professional Courier Aggregator Delivers Seamless Solutions',
      content: 'In todays fast-paced e-commerce landscape, efficient logistics are crucial for success. From order fulfilment to timely deliveries, ',
      date: 'April 2, 2024',
      author: 'Bhuvan Ralhan'
    },
    {
      id: 'Door-to-Door-Pickup-and-Courier-Services',
      imageUrl: doortodoor,
      title: "How Door-to-Door Pickup Services Are Redefining Urban Logistics and convenience for e-commerce e-commerce",
      content: 'In the bustling landscape of urban logistics, where time is of the essence and convenience is paramount, ',
      date: 'May 5, 2024',
      author: 'Anjali Daga'
    },
    {
      id: 'Online-shipping-courier-service-near-me-for-e-commerce-business',
      imageUrl: onlineshipping,
      title: "10 Reasons to choose an Online shipping platform as your delivery partner for E-commerce businesses ",
      content: 'In the vibrant e-commerce world where speed, efficiency and reliability are important, choosing the right delivery ',
      date: 'June 5, 2024',
      author: 'Kashish Chhabra'
    }

  ]
  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true
    });

  }, [])

  return (<React.Fragment>
    <div className='flex flex-col mt-20 pt-8 pb-2'>
      <Helmet>
        <title> Couriero Blog - Latest Courier Service updates.</title>
        <meta name="description" content="Stay updated with the latest news and updates from Couriero. Read our blog for insights, tips, and more about e-commerce and parcel delivery services." />
        <link rel="canonical" href="https://couriero.in/blogs" />
      </Helmet>
      <div className='flex bg-gradient-to-r from-purple-50 to-blue-300      flex-col  lg:flex-row w-full'>
        <div className='flex gap-5 mt-3 py-10   items-center justify-center lg:w-1/2 flex-col'>
          <div className='flex  lg:w-3/4  text-center lg:text-start  items-center mx-2'>
            <h1 className='lg:text-4xl text-3xl 2xl:text-5xl    text-slate-800 font-bold'>
              Welcome to Couriero's Insightful Blogs
            </h1>
          </div>
          <div className='flex text-center lg:text-start  lg:w-3/4 mx-2'>
            <h2 className='text-lg text-indigo-900'>
              Explore with Couriero & gain comprehensive insight into logistics and beyond.
            </h2>
          </div>
          <div className='flex flex-row lg:w-3/4 text-center items-center'>
            <a href='http://app.couriero.in/login' target='_blank' rel="noopener noreferrer">
              <button className='flex py-2 px-4 bg-blue-800 2xl:px-9  text-white font-bold rounded-xl shadow-md rounded-bl-none hover:border duration-300  hover:font-extrabold hover:border-blue-800 hover:bg-white hover:text-blue-900'>Sign-up now
              </button>
            </a>
          </div>
        </div>
        <div data-aos="fade-left" className='lg:flex    hidden lg:w-1/2  '>
          <img src={banner}
            alt="Blog Banner"
            className="p-20 2xl:p-36"
            loading="lazy" />
        </div>
      </div>
      <div className='flex 2xl:px-52 flex-row'>
        <div className='lg:grid w-full mx-5 h-full  lg:grid-flow-row grid-cols-2 gap-2  lg:w-8/12'>
          {blogs.map(blog => (
            <div key={blog.id} className='flex mt-4 p-2 pb-0 shadow-md  justify-between shadow-gray-400 flex-col ml-2 '>
              <Link to={`/blogs/${blog.id}`} className="mt-2">
                <img src={blog.imageUrl}
                  alt={`Blog: ${blog.title}`}
                  className="w-full h-auto rounded-t-lg object-cover"
                  loading="lazy" />
              </Link>
              <div className='flex px-1 flex-col '>
                <Link to={`/blogs/${blog.id}`} className="read-more-link">
                  <h3 className='lg:text-base cursor-pointer hover:underline text-justify lg:text-left font-semibold pt-2'>{blog.title}</h3>
                </Link>
                <p className='lg:text-sm text-sm mt-1 text-justify lg:text-start'>{blog.content}</p>
                <Link to={`/blogs/${blog.id}`} className="mt-2">
                  <h3 className='justify-end  font-bold text-gray-400 text-sm cursor-pointer hover:text-gray-600 hover:underline'>Read More</h3>
                </Link>
              </div>
              <div className='flex px-1 text-sm pt-2 pb-2 flex-row w-full justify-between'>
                <h3 className='flex hover:text-gray-600 cursor-pointer text-gray-400 '>{blog.date} </h3>
                <h3 className='flex hover:text-gray-600 cursor-pointer text-gray-400 pl-16'>by - {blog.author} </h3>
              </div>
            </div>
          ))}
        </div>
        <div className='lg:flex hidden lg:mt-1  lg:flex-col  lg:w-4/12 lg:items-center'>
          <div className='w-3/4 mt-4 items-center flex flex-col bg-gray-200 rounded-lg shadow-md'>
            <h2 className='text-xl font-bold mt-3'>Follow us</h2>
            <div className="flex flex-row my-5 items-center gap-3">
              {[
                { href: "https://twitter.com/CourieroIN", icon: "fa-x-twitter" },
                { href: "https://www.facebook.com/couriero.in", icon: "fa-facebook-f" },
                { href: "https://www.instagram.com/couriero.in/", icon: "fa-instagram" },
                { href: "https://www.youtube.com/channel/UC2nLLn3C2NqBN8toLZzaX4w", icon: "fa-youtube" },
                { href: "https://www.linkedin.com/company/couriero", icon: "fa-linkedin-in" },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  className="flex items-center justify-center w-10 h-10 bg-black text-white rounded-full hover:bg-gray-700 transition-colors duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`fa-brands ${social.icon}`} />
                </a>
              ))}
            </div>
          </div>
          <div data-aos="fade-in" className='flex px-14 pt-8'>
            <img
              className="shadow-xl rounded-lg object-cover"
              alt="couriero-banner"
              src={sidebanner}
            />
          </div>
          <div className='flex flex-col px-14 pt-8'>
            <div className='flex flex-col px-2 pt-5 pb-0  rounded-xl shadow-lg'>
              <div className='flex'>
                <h2 className='font-black'>Table Of Contents</h2>
              </div>
              {[
                "Streamlining E-Commerce Logistics: How Our Professional Courier Aggregator Delivers Seamless Solutions",
                "How Door-to-Door Pickup Services Are Redefining Urban Logistics and Convenience for E-Commerce",
                "10 Reasons to Choose an Online Shipping Platform as Your Delivery Partner for E-Commerce Businesses",
                "Celebrate the Festive Season Courier Services with Couriero: Fast, Reliable, and Affordable",
                "How to Increase Online Sales This Festive Season",
                "8 Best Products to Sell on Diwali in 2024",
              ].map((content, index) => (
                <div
                  key={index}
                  className="flex border-b border-gray-300 py-2 cursor-pointer hover:text-slate-800 transition-colors duration-200"
                >
                  <span className="text-black pr-2">•</span>
                  <h3 className="text-sm text-slate-500">{content}</h3>
                </div>
              ))}
              <div className='flex mb-1 pt-3'>
                <h3 className='text-sm text-slate-700  cursor-pointer font-bold'>Many More to come....</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col 2xl:px-36 lg:flex-row mt-10 justify-center items-center bg-gradient-to-r from-purple-50 to-blue-300 w-full py-4 lg:py-16 lg:px-8">
  {/* Text Section */}
  <div className="flex flex-col px-4 justify-start text-center w-full lg:w-1/2">
    <h3 className="flex w-full lg:w-3/4 cursor-pointer text-2xl lg:text-4xl 2xl:text-5xl font-extrabold lg:pb-2">
      Subscribe to
    </h3>
    <h3 className="flex w-full cursor-pointer text-2xl lg:text-4xl 2xl:text-5xl font-extrabold">
      Couriero Newschannel
    </h3>
  </div>

  {/* Input and Button Section */}
  <div className="flex flex-row px-4 items-center my-2 justify-center w-full lg:w-1/2">
    <input
      className="w-3/4 outline-none pl-4 border border-gray-400 shadow-lg py-2 lg:h-10 lg:text-xl text-sm rounded-lg focus:border-blue-500 transition duration-300"
      placeholder="Enter your e-mail address"
      type="email"
    />
    <button className="lg:px-4 py-2 px-2 border border-gray-400 mx-4 w-1/4 bg-blue-500 shadow-lg text-white rounded-lg text-sm lg:text-2xl font-sans hover:bg-blue-600 transition duration-300">
      Subscribe
    </button>
  </div>
</div>
    </div>
  </React.Fragment>
  )
}

export default BlogPage;
