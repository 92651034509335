import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
  return (
    <React.Fragment>
      <div className='flex flex-col' >
        <Navbar />
        <ToastContainer />
        <div className='z-10 bg-white'>{props.children}</div>

        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
