import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TrackingStatus from "../Assets/TrackShipment/TracingStatus.png"
import CustomerSupport from "../Assets/TrackShipment/CustomerSupport .png"
import TrackYourPackage from "../Assets/TrackShipment/TrackYourPackage.png"
import TrackingTools from "../Assets/TrackShipment/TrackingTools.png"
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify';


const TrackShipment = () => {
    const navigate = useNavigate()

    const [trackAwb, setTrackAwb] = useState('')
    

    const trackAwbOnChange = (e) => {
        setTrackAwb(e.target.value)
    }

    const checkAwbTracking = async (awbNo) => {
        const response = await fetch('https://api.couriero.in/public/awb-track-details', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                awb: awbNo
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            // showToast('awb available ' , 'success' )
            navigate(`/track-shipment/${trackAwb}`)
        } else if (result.status === 'error') {
            // showToast(result.message, 'error')
            toast.error(`${result.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
    }

    const [open1, setopen1] = useState('')
    const [open2, setopen2] = useState('')
    const [open3, setopen3] = useState('')
    const [open4, setopen4] = useState('')
    const [open5, setopen5] = useState('')

    const toogleQuestion1 = () => {
        setopen1(!open1)
        setopen2(false)
        setopen3(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion2 = () => {
        setopen2(!open2)
        setopen1(false)
        setopen3(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion3 = () => {
        setopen3(!open3)
        setopen2(false)
        setopen1(false)
        setopen4(false)
        setopen5(false)
    }
    const toogleQuestion4 = () => {
        setopen4(!open4)
        setopen3(false)
        setopen2(false)
        setopen1(false)
        setopen5(false)
    }
    const toogleQuestion5 = () => {
        setopen5(!open5)
        setopen4(false)
        setopen3(false)
        setopen2(false)
        setopen1(false)
    }

    const [showInfo, setShowInfo] = useState(false)
    const [showInfo2, setShowInfo2] = useState(false)
    const [showInfo3, setShowInfo3] = useState(false)
    const [showInfo4, setShowInfo4] = useState(false)

    return (
        <React.Fragment>
            <div className='flex flex-col w-full h-full'>
                <Helmet>
                    <title>Track Your Shipments with Couriero - Real-Time Updates</title>
                    <meta name="description" content="Easily track your shipments with Couriero. Get real-time updates and notifications on your parcel delivery status from our network of 15+ trusted courier partners." />
                    <link rel="canonical" href="https://couriero.in/track-shipment" />
                </Helmet>
                <div className='flex px-4 2xl:px-60 py-4 justify-center items-center bg-gradient-to-r from-purple-50 to-blue-300  flex-col lg:flex-row mt-20 lg:justify-between '>
                    <div className='flex justify-center lg:items-start items-center gap-2 flex-col w-full  lg:w-1/2 '>
                        <h1 className='lg:text-5xl text-center text-[1.7rem] font-semibold text-blue-800'>
                            Where is your Shipment?
                        </h1>
                        <h2 className='lg:text-5xl pr-2 text-3xl text-slate-800 font-semibold'>
                            Let's find out
                        </h2>
                        <h3 className='text-lg pr-2 lg:text-start text-center pt-4 font-medium'>
                            Keep track of your orders for a smoother and more enjoyable experience.
                        </h3>
                    </div>

                    <div className="max-w-lg   lg:mx-auto lg:w-1/2 2xl:w-2/6 my-4 py-4  w-full px-4  lg:p-6 border border-gray-300  bg-white rounded-2xl shadow-md">
                        <div className='flex text-center justify-center'>
                            <h2 className="lg:text-3xl text-2xl font-bold mb-6 text-slate-800 text-center">Track Your Shipment
                            </h2>
                        </div>

                        <form onSubmit={(e) => {
                            e.preventDefault()
                            checkAwbTracking(trackAwb)
                        }} className='w-full flex flex-col '>
                            <div className="mb-4">
                                <div className='flex flex-row space-x-6 mb-3 pl-1'>
                                    <div className='space-x-2 items-center flex'>
                                        <h3 className="text-base text-slate-700 cursor-pointer font-bold">Enter Awb Number</h3>
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    onChange={trackAwbOnChange}
                                    placeholder='Enter AWB Number'
                                    className="w-full px-4 py-2 border  rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full font-bold bg-blue-400 text-white py-2 px-4 rounded-md hover:bg-opacity-90 duration-300"
                            >
                                Track Shipment
                                <span></span>
                            </button>
                        </form>
                        <div className='flex flex-col mt-4 space-y-1'>
                            <h3 className='text-slate-800  lg:font-bold font-semibold text-lg'>Can't Find Your Order Details?</h3>
                            <p className='lg:font-semibold lg:pt-0 pt-1 font-medium text-sm text-slate-700'>We sent your AWB Tracking number to you via SMS upon order confirmation.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col  justify-center w-full'>
                    <div className='flex mb-2 justify-center items-center'>
                        <h2 className="flex  flex-row justify-center  lg:px-6 px-2  py-5 bg-gradient-to-r text-transparent from-blue-600 via-purple-600 to-pink-600 text-3xl text-center lg:text-4xl bg-clip-text  font-bold">Unlock the Secrets of Order Tracking</h2>
                    </div>
                    <div className='flex 2xl:px-60 cursor-pointer lg:flex-row flex-col w-full  px-4  lg:space-x-3 '>
                        <div className="flex flex-row lg:w-1/4 h-[13.6rem]  w-full my-4 rounded-xl border  border-black  bg-gradient-to-tr   shadow-lg drop-shadow-md shadow-gray-500 space-y-3 px-2 justify-center items-center text-center" onMouseEnter={() => setShowInfo(true)}
                            onMouseLeave={() => setShowInfo(false)}>
                            <div className='flex py-5 flex-col items-center gap-5'>
                                <img
                                    src={TrackingStatus}
                                    className={`w-28 h-24 ${showInfo ? 'hidden' : ''}`}
                                    alt="Illustration of tracking status updates for shipment"
                                    title="Tracking Status Updates - Couriero"
                                />
                                <h3 className={`flex font-bold text-slate-800 underline-offset-4 underline ${showInfo ? 'hidden' : ''} `}>
                                    Understand about tracking status
                                </h3>
                            </div>
                            {showInfo && (
                                <div className='  w-full py-3  rounded-xl bg-gradient-to-tr  flex space-y-3  flex-col justify-center items-center text-center '>
                                    <h3 className='flex font-bold text-slate-800 underline-offset-4 underline '>
                                        Understand about tracking status
                                    </h3>
                                    <p className='flex flex-row text-slate-700 text-center px-2'>
                                        Familiarize yourself with common tracking statuses such as "Processing", "In Transit," "Out for Delivery," and "Delivered".
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row lg:w-1/4 h-[13.6rem]   w-full my-4 rounded-xl border  border-black  bg-gradient-to-tr   shadow-lg drop-shadow-md shadow-gray-500 space-y-3 px-2 justify-center items-center text-center" onMouseEnter={() => setShowInfo2(true)}
                            onMouseLeave={() => setShowInfo2(false)}>
                            <div className='flex py-5 flex-col items-center gap-5'>
                                <img
                                    src={TrackYourPackage}
                                    className={`w-28 h-24 ${showInfo2 ? 'hidden' : ''}`}
                                    alt="Track Your Package illustration for real-time shipment updates"
                                    title="Track Your Package - Real-Time Shipment Updates"
                                />
                                <h3 className={`flex font-bold text-slate-800 underline-offset-4 underline ${showInfo2 ? 'hidden' : ''} `}>
                                    Track Your Package
                                </h3>
                            </div>
                            {showInfo2 && (
                                <div className='  w-full py-3  rounded-xl bg-gradient-to-tr  flex space-y-3  flex-col justify-center items-center text-center '>
                                    <h3 className='flex font-bold text-slate-800 underline-offset-4 underline '>
                                        Track Your Package
                                    </h3>
                                    <p className='flex flex-row text-slate-700 text-center px-2'>
                                        Stay informed on your package's location and estimated delivery time. Ensure a smooth and timely delivery by tracking your package.
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row lg:w-1/4 h-[13.6rem]  w-full my-4 rounded-xl border  border-black  bg-gradient-to-tr   shadow-lg drop-shadow-md shadow-gray-500 space-y-3 px-2 justify-center items-center text-center" onMouseEnter={() => setShowInfo3(true)}
                            onMouseLeave={() => setShowInfo3(false)}>
                            <div className='flex py-5 flex-col items-center gap-5'>
                                <img
                                    src={TrackingTools}
                                    className={`w-20 h-20 ${showInfo3 ? 'hidden' : ''}`}
                                    alt="How to Track Using Our Tracking Tools"
                                    title="Tracking Tools - Guide to Using Our Tracking Features"
                                />
                                <h3 className={`flex font-bold text-slate-800 underline-offset-4 underline ${showInfo3 ? 'hidden' : ''} `}>
                                    How to track using our tracking tools
                                </h3>
                            </div>
                            {showInfo3 && (
                                <div className='  w-full py-3  rounded-xl bg-gradient-to-tr  flex space-y-3  flex-col justify-center items-center text-center '>
                                    <h3 className='flex font-bold text-slate-800 underline-offset-4 underline '>
                                        How to track using our tracking tools
                                    </h3>
                                    <p className='flex flex-row text-slate-700 text-center px-2'>
                                        Learn how to navigate and use our tracking tools for real-time updates. Discover features like estimated delivery times and location tracking.
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row lg:w-1/4 h-[13.6rem]  w-full my-4 rounded-xl border  border-black  bg-gradient-to-tr   shadow-lg drop-shadow-md shadow-gray-500 space-y-3 px-2 justify-center items-center text-center" onMouseEnter={() => setShowInfo4(true)}
                            onMouseLeave={() => setShowInfo4(false)}>
                            <div className='flex py-5 flex-col items-center gap-5'>
                                <img
                                    src={CustomerSupport}
                                    className={`w-24 h-24 ${showInfo4 ? 'hidden' : ''}`}
                                    alt="Need Help? Contact Customer Support"
                                    title="Customer Support - Reach Out for Assistance"
                                />

                                <h3 className={`flex font-bold text-slate-800 underline-offset-4 underline ${showInfo4 ? 'hidden' : ''} `}>
                                    Need Help? Contact Customer Support
                                </h3>
                            </div>
                            {showInfo4 && (
                                <div className='  w-full py-3  rounded-xl bg-gradient-to-tr  flex space-y-3  flex-col justify-center items-center text-center '>
                                    <h3 className='flex font-bold text-slate-800 underline-offset-4 underline '>
                                        Need Help? Contact Customer Support
                                    </h3>
                                    <p className='flex flex-row text-slate-700 text-center px-2'>
                                        For any tracking-related issues, our customer support team is here to assist you. Get in touch for prompt resolution and support.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mb-4 mt-10 px-4 lg:px-36 flex-col w-full h-full'>
                    <h2 className=' mb-4 pb-3  underline-offset-8 underline bg-gradient-to-r text-transparent from-blue-600 via-purple-600 to-pink-600  text-center lg:text-4xl text-3xl bg-clip-text  font-bold   '>
                        Tracking FAQs
                    </h2>
                    <div className='flex 2xl:px-44  flex-col space-y-3 w-full mt-4'>
                        <div className='flex flex-col items-center shadow-md pl-2 w-full'>
                            <div onClick={toogleQuestion1} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    1. How can I track my package?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1  py-1 rounded-full ${open1 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open1 &&
                                <div className=' w-full'>
                                    <p className='pb-3 text-justify mb-2 pr-4 font-medium text-base'>You can track your package by using the tracking link provided by Couriero via SMS. Simply enter the tracking AWB number or Order ID on the couriero website.</p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion2} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    2. What do different tracking status mean?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open2 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open2 &&
                                <div className=' w-full'>
                                    <p className='pb-3 text-justify mb-2 pr-4 font-medium text-base '>"Manifested":Your package's information has been registered in the courier system and is awaiting pickup."In transit":Your package is currently en route to its destination."Delivery Destination":Your package has reached the delivery destination hub and is being prepared for delivery to your address."Out for Delivery": Your package is with the local courier for delivery to your address."Delivered": Your package has been successfully delivered to the recipient."RTO In Transit": Your package is on its way back to the sender (Return to Origin)."RTO Delivered": Your package has been successfully delivered back to the sender (Return to Origin).</p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion3} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    3. My tracking hasn't updated in a while. What should I do?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open3 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open3 &&
                                <div className=' w-full'>
                                    <p className='pb-3 mb-2 font-medium pr-4 text-base'>If your tracking hasn't updated for some time, it could be due to delays in scanning or updating by the courier company.However, if you're concerned, you can mail us on support@couriero.in </p>
                                </div>}
                        </div>
                        <div className='flex shadow-md flex-col items-center pl-2 w-full'>
                            <div onClick={toogleQuestion4} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    4.  Can I change the delivery address or Contact Details?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open4 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open4 &&
                                <div className=' w-full'>
                                    <p className='pb-3 text-justify pr-4 mb-2 font-medium text-base'>
                                        The delivery address or Contact Details can be updated by raising a ticket on the Couriero website.
                                    </p>
                                </div>}
                        </div>
                        <div className='flex flex-col shadow-md items-center pl-2 w-full'>
                            <div onClick={toogleQuestion5} className='flex cursor-pointer flex-row w-full'>
                                <h3 className="lg:text-xl text-lg w-11/12 text-slate-800 font-semibold mb-4">
                                    5.  My package shows as "Delivered," but I haven't received it. What should I do?
                                </h3>
                                <div className='w-1/12 text-end pr-3'>
                                    <i className={`fa-solid bg-blue-300 px-1 py-1 rounded-full ${open5 ? 'fa-angle-up' : 'fa-angle-down'}`} />
                                </div>
                            </div>
                            {open5 &&
                                <div className='w-full '>
                                    <p className='pb-3 mb-2 text-justify pr-4 item font-medium text-base '>For further assistance or inquiries, please don't hesitate to email us at support@couriero.in for details.</p>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TrackShipment
