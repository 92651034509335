import Shippingpartners from "../Assets/Homepage/ShippingPartners.png"
import Shippingpartners2 from "../Assets/Homepage/ShippingPartners2.png"
import HomePageBanner from "../Assets/Homepage/Homepagebanner2.mp4"
import HomePageBannerPhone from "../Assets/Homepage/HomePageBannerPhonenew.mp4"
import Growthanimation from "../Assets/Homepage/Growthanimation.mp4"
import '../App.css'
import React, { useState, useEffect } from "react";
import Reliability from "../Assets/Homepage/Reliablity.png"
import Affordable from "../Assets/Homepage/Affordable.png"
import Rating from "../Assets/Homepage/Rating.png"
import deliverytruck from "../Assets/Homepage/delivery-truck.png"
import cooperation from "../Assets/Homepage/cooperation.png"
import calculator from "../Assets/Homepage/calculator.png"
import rupee from "../Assets/Homepage/rupee.png"
import Realtime from "../Assets/Homepage/Real-time.png"
import Cashflow from "../Assets/Homepage/Cashflow.png"
import Expanded from "../Assets/Homepage/Expanded.png"
import Avni from "../Assets/Homepage/avni.png"
import Rani from "../Assets/Homepage/rani.png"
import Sahil from "../Assets/Homepage/sahil.png"
import Divyam from "../Assets/Homepage/divyam.png"
import Khushi from "../Assets/Homepage/khushi.png"
import chuooranchacha from "../Assets/Homepage/chuooranchacha.png"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'




function HomeNew() {

    const banners = [
        {
            text: "Re-Defining The Future Of Shipping",
        },
        {
            text: "Setting New Standards in Courier Services",
        },
        {
            text: "Turning Deliveries Into Seamless Experiences",

        },
    ];

    const [isFading, setIsFading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
                setIsFading(false);
            }, 400);
        }, 5000);
        return () => clearInterval(interval);
    });


    return (

        <div className="flex  bg-white flex-col mt-20">
            <Helmet>
                <title>Courier Aggregator Solution for eCommerce Businesses</title>
                <meta name="description" content="Use Couriero to learn about seamless courier options. Use our dependable platform to streamline logistics and find dependable courier partners for hassle-free, quick, and reasonably priced deliveries." />
                <link rel="canonical" href="https://couriero.in" />
            </Helmet>
            <div className="md:flex hidden justify-center items-center w-full">
                <video loop autoPlay muted src={HomePageBanner} typeof="video/mp4" alt="banner" />
            </div>
            <div className="sm:flex md:hidden justify-center items-center w-full">
                <video loop autoPlay muted src={HomePageBannerPhone} typeof="video/mp4" alt="banner" />
            </div>
            <div class="absolute lg:mt-[7rem] mt-14  w-full ">
                <div className="flex    items-center text-center justify-center flex-col w-full">
                    <div className="flex  selection:bg-blue-50 selection:bg-opacity-40 flex-col gap-2 ">
                        <h1
                            className={`px-72 leading-snug lg:text-[2.4rem] text-4xl text-transparent lg:pb-2  bg-clip-text bg-gradient-to-r from-blue-600 to-purple-700   font-bold ${isFading ? "opacity-0" : "opacity-100"
                                }`}
                        >
                            {banners[currentIndex].text.split(" ").map((word, index) => (
                                <span key={index}>{word + " "}</span>
                            ))}
                        </h1>
                        <h2 className="text-slate-600 text-lg font-semibold  lg:text-xl ">Witness The Most Powerful Shipping Platform</h2>
                    </div>

                    <div className="flex flex-row justify-center items-center w-full">
                        <Link to="/login">
                            <button class="relative lg:mt-[2rem] mt-10 pr-11 pl-7 hover:shadow-md  py-3 bg-opacity-40 hover:rounded-3xl hover:bg-opacity-70 hover:duration-300  hover:bg-blue-900 text-slate-800 hover:text-white font-semibold text-xl rounded-3xl bg-white group">
                                Discover More
                                <i class="fa-solid fa-arrow-right absolute pl-2 pt-1 transition-transform duration-300 ease-in-out group-hover:translate-x-1"></i>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex   mt-10 flex-col justify-center items-center">
                <div className="flex w-full justify-center flex-col items-center">
                    <h2 className=" pb-3 lg:text-[2.5rem] text-[2rem]  bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent font-semibold bg-clip-text">Our Growth</h2>
                    <h3 className="lg:text-center text-justify lg:px-10 font-normal px-7 lg:w-4/6">
                        At Couriero, customer happiness and innovation are the main drivers of our success. We have grown from a startup to a reputable courier aggregator by consistently improving our offerings to satisfy changing logistics demands.</h3>
                </div>
                <div className="flex 2xl:px-60  pt-7 w-full justify-center items-center flex-col lg:flex-row">
                    <div className="flex lg:mr-0 mr-12 gap-5 justify-center items-center  py-4 lg:py-0  flex-col lg:w-1/2">
                        <div className="bg-green-50  cursor-pointer group py-2 px-3  rounded-lg lg:w-2/5 flex
                         flex-row ">
                            <div className="flex w-3/4 flex-col">
                                <h3 className="flex font-semibold text-2xl">30,000+</h3>
                                <h3 className="flex text-xs">Satiesfied Sellers</h3>
                            </div>
                            <div className="flex w-1/4 justify-center items-center" >
                                <img
                                    src={Rating}
                                    alt="Couriero Customer Rating"
                                    title="Couriero Customer Rating - Trusted by Our Users"
                                    className="w-12 h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="bg-blue-50 lg:ml-56 ml-28 group py-2 px-3 cursor-pointer  rounded-lg lg:w-2/5 flex flex-row ">
                            <div className="flex w-3/4 flex-col">
                                <h3 className="flex font-semibold text-2xl">30,000+</h3>
                                <h3 className="flex text-xs">Daily Shipments</h3>
                            </div>
                            <div className="flex w-1/4 justify-center items-center" >
                                <img
                                    src={deliverytruck}
                                    alt="Couriero Delivery Truck"
                                    title="Couriero Delivery Truck - Reliable Delivery Services"
                                    className="w-12 h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <div className="bg-yellow-50  py-2 px-3 group cursor-pointer  rounded-lg lg:w-2/5 flex flex-row ">
                            <div className="flex w-3/4 flex-col">
                                <h3 className="flex font-semibold text-2xl">93.3%</h3>
                                <h3 className="flex text-xs">On Time Delivery</h3>
                            </div>
                            <div className="flex w-1/4 justify-center items-center" >
                                <img
                                    src={Realtime}
                                    alt="Couriero Real-time Tracking"
                                    title="Couriero Real-time Tracking - Stay Updated with Your Shipments"
                                    className="w-12 h-12 transition-transform duration-300 ease-in-out group-hover:translate-x-2"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center lg:w-1/2">
                        <div className="flex w-3/4 mt-6 lg:mt-0 justify-center items-center lg:w-2/4">
                            <video loop autoPlay muted src={Growthanimation} typeof="video/mp4" alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:flex 2xl:px-60   bg-gradient-to-b from-white to-blue-200 flex-col w-full lg:my-10 justify-center items-center">
                <div className="relative  flex flex-col lg:pb-8 pb-5 pt-5  justify-center items-center h-full">
                    <h2 className="font-bold  font-sans pb-2 lg:text-[2.5rem] text-[1.8rem]  bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent bg-clip-text ">Top Couriero Features</h2>
                </div>
                <div className="flex lg:flex-row flex-col w-full   gap-10 lg:px-14 px-12 pb-5 lg:pt-2">
                    <div className=" bg-white px-6 lg:py-0 py-3  bg-opacity-30  cursor-pointer   hover:bg-opacity-50  flex  hover:shadow-xl shadow-lg rounded-2xl   flex-col lg:px-3 lg:w-1/4">
                        <div className="flex pl-3 border-b border-slate-400  gap-3 justify-start py-3  flex-row">
                            <div className="rounded-full bg-slate-200 border  p-3 items-center flex justify-center">
                                <img
                                    src={Reliability}
                                    alt="Couriero Reliability Feature Logo"
                                    title="Couriero Reliability Feature - Trusted Delivery Services"
                                    className="w-8 h-8"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <h3 className="flex items-center pt-3  font-bold text-[#DF8113] text-[1.6rem]">Reliability</h3>
                            </div>
                        </div>
                        <div className="flex border-b pl-3 border-slate-400 flex-col">
                            <h3 className="flex pl-1 font-bold pt-5">Upto</h3>
                            <div className="flex pb-3 gap-1 flex-row">
                                <h3 className="text-6xl font-bold  text-blue-900">30%</h3>
                                <h3 className="flex items-end text-base font-semibold pb-2">Less Rto</h3>
                            </div>
                        </div>
                        <div className="flex pt-3 pl-3 pb-2 flex-col">
                            <h3 className="font-bold text-xl">Less Returns</h3>
                            <h3 className="font-medium text-slate-700">More Success</h3>
                        </div>
                    </div>
                    <div className="bg-white px-6 lg:py-0  py-3  bg-opacity-30  cursor-pointer   hover:bg-opacity-50  flex  hover:shadow-xl shadow-lg rounded-2xl   flex-col lg:px-3 lg:w-1/4">
                        <div className="flex border-b border-slate-400  gap-3 justify-center py-3 flex-row">
                            <div className="rounded-full bg-slate-200 border p-3 items-center flex justify-center">
                                <img
                                    src={Affordable}
                                    alt="Couriero Affordable Shipping Feature Logo"
                                    title="Couriero Affordable Shipping - Cost-effective Delivery Solutions"
                                    className="w-8 h-8"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <h3 className="flex items-center pt-3  font-bold text-[#DF8113] text-[1.6rem] text-3xl">Affordable</h3>
                            </div>
                        </div>

                        <div className="flex border-b pl-3 border-slate-400 flex-col">
                            <h3 className="flex pl-1 font-bold pt-5">Upto</h3>
                            <div className="flex pb-3 gap-1 flex-row">
                                <h3 className="text-6xl font-bold  text-blue-900">20%</h3>
                                <h3 className="flex items-end text-base font-semibold pb-2">Less Cost</h3>
                            </div>
                        </div>
                        <div className="flex pt-3 pl-3 pb-2 flex-col">
                            <h3 className="font-bold text-xl">Cost-Effective</h3>
                            <h3 className="font-medium text-slate-700">Shipping</h3>
                        </div>
                    </div>
                    <div className="bg-white px-6 lg:py-0  py-3   bg-opacity-30  cursor-pointer   hover:bg-opacity-50  flex  hover:shadow-xl shadow-lg rounded-2xl   flex-col lg:px-3 lg:w-1/4">
                        <div className="flex border-b border-slate-400  gap-3 justify-center py-3 flex-row">
                            <div className="rounded-full bg-slate-200 border p-3 items-center flex justify-center">
                                <img
                                    src={Expanded}
                                    alt="Couriero Expanded Delivery Options Feature Logo"
                                    title="Couriero Expanded Delivery Options - Flexible Shipping Solutions"
                                    className="w-8 h-8"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <h3 className="flex items-center pt-3 text-[1.6rem] font-bold text-[#DF8113] text-3xl">Expanded</h3>
                            </div>
                        </div>

                        <div className="flex border-b pl-3  border-slate-400 flex-col">
                            <h3 className="flex pl-1 font-bold pt-5">Upto</h3>
                            <div className="flex pb-3  gap-1 flex-row">

                                <h3 className="text-6xl  font-bold  text-blue-900">24K+</h3>

                                <h3 className="flex items-end text-base font-semibold pb-2">Pincode</h3>
                            </div>
                        </div>
                        <div className="flex pt-3  pl-3 pb-2 flex-col">
                            <h3 className="font-bold text-xl">Reliable Network</h3>
                            <h3 className="font-medium text-slate-700">Wide Range</h3>
                        </div>
                    </div>
                    <div className="bg-white px-6 lg:py-0  py-3   bg-opacity-30  cursor-pointer   hover:bg-opacity-50  flex  hover:shadow-xl shadow-lg rounded-2xl   flex-col lg:px-3 lg:w-1/4">
                        <div className="flex border-b border-slate-400  gap-3 justify-center py-3 flex-row">
                            <div className="rounded-full bg-slate-200 border p-3 items-center flex justify-center">
                                <img
                                    src={Cashflow}
                                    alt="Couriero Cashflow Management Feature Logo"
                                    title="Couriero Cashflow Management - Streamlined Payment Solutions"
                                    className="w-8 h-8"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <h3 className="flex items-center pt-3 text-[1.6rem] font-bold text-[#DF8113] text-3xl">Cashflow</h3>
                            </div>
                        </div>

                        <div className="flex border-b pl-3 border-slate-400 flex-col">
                            <h3 className="flex pl-1 font-bold pt-5">Upto</h3>
                            <div className="flex pb-2 gap-1 flex-row">
                                <h3 className="text-6xl font-bold  text-blue-900">80%</h3>
                                <h3 className="flex items-end text-lg font-semibold pb-2">Better Cashflow</h3>
                            </div>
                        </div>
                        <div className="flex py-3 pl-3 flex-col">
                            <h3 className="font-bold text-xl">COD Remittance</h3>
                            <h3 className="font-medium text-slate-700">Twice a Week</h3>
                        </div>
                    </div>
                </div>
                <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                    <div className="flex flex-row pb-5 mt-4  mb-3 justify-center w-full">
                        <button className="bg-blue-900  text-white px-7 py-3 border border-blue-900 font-bold text-sm rounded-md shadow-xl hover:bg-white hover:text-blue-900 hover:duration-300 hover:shadow-xl hover:shadow-slate-300 hover:divide-x-8">Sign up for free</button>
                    </div>
                </a>
            </div>
            <div className="flex  2xl:px-72 lg:px-16 md:px-16 px-8 flex-col">
                <div className="flex my-10 lg:mt-0 md:mt-0  justify-center items-center">
                    <h2 className="font-bold font-sans pb-2  text-[1.8rem]  lg:text-[2.5rem] bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent bg-clip-text">Why Choose Us?</h2>
                </div>
                <div className="flex lg:px-14 md:px-14 px-7   rounded-2xl  bg-[#E0F9FDA8] lg:flex-row md:flex-row flex-col justify w-full">
                    <div className="flex lg:w-4/6 md:w-4/6 w-full justify-center flex-col">
                        <h3 className="flex lg:text-3xl pt-5 lg:pt-0 md:pt-0 md:text-3xl text-lg justify-center lg:justify-start md:justify-start font-semibold">15+ Couriero Partner</h3>
                        <h3 className="flex text-sm lg:w-3/4 md:w-3/4 w-full pt-3 font-light text-justify">We offer you access to over 15 reliable courier partners, ensuring your packages are delivered promptly and safely. With our extensive network, you can choose the best courier for your shipping needs, whether it's for domestic or international delivery.</h3>
                    </div>
                    <div className="flex p-5 lg:w-2/6 md:w-2/6 w-full ">
                        <div className="flex bg-white bg-opacity-40 rounded-lg  ">
                            <img
                                src={cooperation}
                                alt="Couriero Cooperation and Partnership"
                                title="Couriero Cooperation and Partnership - Building Strong Relationships"
                                className="p-10"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex mt-5 gap-8 w-full lg:flex-row md:flex-row flex-col justify-center items-center">
                    <div className="flex  bg-[#F9EBFEA8] flex-col md:w-1/2 lg:w-1/2 w-full rounded-xl">
                        <div className="flex  p-8 justify-center gap-4 items-center flex-col">
                            <h3 className="flex lg:text-3xl  md:text-3xl text-lg  font-semibold">Shipping Rates Calculator</h3>
                            <h3 className="flex font-light px-2 text-justify  text-sm">Our shipping rates calculator allows you to instantly determine the cost of sending your packages. Simply input the necessary details, and you'll receive accurate pricing from various couriers.</h3>
                            <div className="flex px-3  w-full ">
                                <div className="flex bg-white mt-4 bg-opacity-40 mx-2  rounded-lg  ">
                                    <img
                                        src={calculator}
                                        alt="Couriero Shipping Cost Calculator"
                                        title="Couriero Shipping Cost Calculator - Estimate Your Delivery Costs"
                                        className="lg:px-28 md:px-28 lg:py-0 md:py-0 p-10"
                                        loading="lazy"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex  bg-[#EBFEF2A8] flex-col md:w-1/2 lg:w-1/2 w-full rounded-xl">
                        <div className="flex  p-8 justify-center gap-4 items-center flex-col">
                            <h3 className="flex lg:text-3xl  md:text-3xl text-lg  font-semibold">Best Shipping Rates</h3>
                            <h3 className="flex font-light px-2 text-justify  text-sm">We provide you with the best shipping rates available, leveraging our partnerships with multiple couriers. Compare prices and choose the most economical option without compromising on service.</h3>
                            <div className="flex px-3  w-full ">
                                <div className="flex bg-white mt-4 bg-opacity-40 mx-2  rounded-lg  ">
                                    <img
                                        src={rupee}
                                        alt="Couriero Currency Symbol"
                                        title="Couriero Currency Symbol - Representing Affordable Shipping Costs"
                                        className="lg:px-28 md:px-28 lg:py-0 md:py-0 p-10"
                                        loading="lazy"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex 2xl:px-60 flex-col justify-center items-center">
                <div className="flex flex-row mt-9 justify-center items-center">
                    <h2 className="font-bold font-sans pb-2 text-[1.8rem]  lg:text-[2.5rem] bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent bg-clip-text ">Our Shipping Partners</h2>
                </div>
                <div className="lg:flex hidden flex-row justify-center w-full mb-5 ">
                    <img
                        alt="Couriero Shipping Partners"
                        src={Shippingpartners2}
                        title="Couriero Shipping Partners - Trusted Couriers for Reliable Deliveries"
                        loading="lazy"
                    />
                </div>
                <div className="flex mt-5 lg:hidden flex-row justify-center w-full mb-5 ">
                    <img
                        alt="Couriero Shipping Partners"
                        src={Shippingpartners}
                        title="Couriero Shipping Partners - Your Trusted Delivery Network"
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="flex 2xl:my-10 flex-col bg-gradient-to-b from-white to-blue-200">
                <div className="flex flex-row justify-center items-center">
                    <h2 className=" font-bold py-5 lg:text-[2rem] text-[1.8rem]  bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent bg-clip-text ">Join Couriero Today</h2>
                </div>
                <div className="flex flex-row justify-center items-center">
                    <h3 className="lg:text-xl text-lg px-2  lg:px-20 py-3  text-center">More Than 30,000 E-Commerce Sellers Have Trusted Couriero For Their Logistics Need
                        Join Them Now To Grow Your Business With Ease</h3>
                </div>
                <div className="flex flex-row py-3 mt-4  mb-3 justify-center w-full">
                    <Link to="/login">
                        <button className="bg-blue-900  text-white px-10 py-3 border border-blue-900 font-bold text-sm rounded-full shadow-xl hover:bg-white hover:text-blue-900 hover:duration-300 hover:shadow-xl hover:shadow-slate-300">Get Started for free</button>
                    </Link>
                </div>
            </div>
            <div className="flex 2xl:px-60  mb-6 lg:px-24 px-2 pb-16 pt-10  bg-[#ECF6FF] flex-col justify-center items-center">
                <div className="pb-6">
                    <h2 className="font-bold font-sans pb-2  text-[2.5rem]  bg-gradient-to-br from-orange-400  to-indigo-700 text-transparent bg-clip-text ">Testimonials</h2>
                </div>
                <div className="lg:flex hidden  overflow-hidden  w-full bg-[#E1EDF8] gap-5 flex-col justify-center rounded-full   py-10 items-center">
                    <div className="flex cursor-pointer  gap-2 group   flex-row w-full" >
                        <div className="flex flex-shrink-0     animate-animat gap-4 w-full">
                            <div className="lg:w-full    items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  lg:w-1/4 rounded-full justify-center items-center">
                                    <div className="flex border flex-row overflow-hidden bg-slate-400 rounded-full">
                                        <img
                                            src={Sahil}
                                            alt="Sahil Chhabra Email"
                                            title="Sahil Chhabra Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex lg:w-3/4 flex-col">
                                    <h3 className="text-sm">Sahil Arora</h3>
                                    <p className="text-[11px] text-start text-slate-600">Since switching to Couriero, our shipping issues have been solved. Their dedication to customer satisfaction is truly appreciable.</p>
                                </div>
                            </div>
                            <div className="w-full items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  w-1/4 rounded-full justify-center items-center">
                                    <div className="flex overflow-hidden border flex-row bg-slate-400 rounded-full">
                                        <img
                                            src={Rani}
                                            alt="Rani Email"
                                            title="Rani Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-3/4 flex-col">
                                    <h3 className="text-sm">Rani khurana</h3>
                                    <p className="text-[11px] text-start text-slate-600">Couriero's efficient solutions and consistent communication have improved our shipping process. They are a trusted partner for our business.</p>
                                </div>
                            </div>
                            <div className="w-full items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  w-1/4 rounded-full justify-center items-center">
                                    <div className="flex border overflow-hidden flex-row bg-slate-400 rounded-full">
                                        <img
                                            src={Khushi}
                                            alt="Khushi Email"
                                            title="Khushi Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-3/4 flex-col">
                                    <h3 className="text-sm">Khushi Kumar</h3>
                                    <p className="text-[11px] text-start text-slate-600"> User-friendly platform has helped us alot in our growth. We highly recommend their services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-shrink-0     animate-animat gap-4 w-full">
                            <div className="w-full items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  w-1/4 rounded-full justify-center items-center">
                                    <div className="flex border flex-row overflow-hidden bg-slate-400 rounded-full">
                                        <img
                                            src={Avni}
                                            alt="Avni Email"
                                            title="Avni Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-3/4 flex-col">
                                    <h3 className="text-sm">Avni soni</h3>
                                    <p className="text-[11px] text-start text-slate-600">We trust Couriero with all our shipping needs. Their  real-time tracking service is great.</p>
                                </div>
                            </div>
                            <div className="w-full items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  w-1/4 rounded-full justify-center items-center">
                                    <div className="flex border flex-row overflow-hidden bg-slate-400 rounded-full">
                                        <img
                                            src={Divyam}
                                            alt="Divyam Email"
                                            title="Divyam Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-3/4 flex-col">
                                    <h3 className="text-sm">Pawan Kumar Sharma</h3>
                                    <p className="text-[11px] text-start text-slate-600">Excellent services and timely delivery records make this company special for our business. happy customer!!!!</p>
                                </div>
                            </div>
                            <div className="w-full items-center  bg-white  gap-5 px-5 py-4  border shadow-lg rounded-full  flex flex-row">
                                <div className="flex  w-1/4 rounded-full justify-center items-center">
                                    <div className="flex overflow-hidden border flex-row bg-slate-300 rounded-full">
                                        <img
                                            src={chuooranchacha}
                                            alt="Chuooran Chacha Email"
                                            title="Chuooran Chacha Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex w-3/4 flex-col">
                                    <h3 className="text-sm">Chirag Sharma</h3>
                                    <p className="text-[11px] text-start text-slate-600">We are really happy working with courier, just been a few months. Their support team is always responsive and helpful</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden overflow-hidden w-full bg-[#E1EDF8] gap-5 flex-col justify-center rounded-full   py-10 items-center">
                    <div className="flex animate-animatPhone  cursor-pointer  gap-2 group   flex-row w-11/12" >
                        <div className="flex   flex-shrink-0   gap-4 w-full">
                            <div className="flex gap-1 w-full  bg-white  shadow-lg border-slate-500 rounded-full flex-row">
                                <div className="p-2 flex flex-row w-1/4">
                                    <div className="flex overflow-hidden border flex-row bg-slate-300 rounded-full">
                                        <img
                                            src={Sahil}
                                            alt="Sahil Chhabra Email"
                                            title="Sahil Chhabra Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex  w-3/4 justify-center    flex-col">
                                    <h3 className="flex text-sm items-start">Sahil Arora</h3>
                                    <h3 className="text-[11px] text-start text-slate-600">Since switching to Couriero, our shipping issues have been solved. Their dedication to customer satisfaction is truly appreciable.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex   flex-shrink-0   gap-4 w-full">
                            <div className="flex gap-1 w-full  bg-white  shadow-lg border-slate-500 rounded-full flex-row">
                                <div className="p-2 flex flex-row w-1/4">
                                    <div className="flex overflow-hidden border flex-row bg-slate-300 rounded-full">
                                        <img
                                            src={Rani}
                                            alt="Rani Email"
                                            title="Rani Email - Get in Touch"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                                <div className="flex  w-3/4 justify-center    flex-col">
                                    <h3 className="flex text-sm items-start">Rani khurana</h3>
                                    <h3 className="text-[11px] text-start text-slate-600">Couriero's efficient solutions and consistent communication have improved our shipping process. They are a trusted partner for our business.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNew;