import React from "react";
import blog from "../Assets/Features/BestShippingRatesnew.png"
import COD from "../Assets/Features/CODRemittance.png"
import payment from "../Assets/Features/payment.png"
import Notification from "../Assets/Features/notification.png"
import Dashboard from "../Assets/Features/dashboard.png"
import Banner from "../Assets/Features/Banner.png"
import easy from "../Assets/Features/easy.png"
import NDR from "../Assets/Features/NDR.png"
import brandedtracking from "../Assets/Features/brandedtracking.png"
import ChannelIntegration from "../Assets/Features/ChannelIntegration.png"
import CourieroPartners from "../Assets/Features/CourieroPartnersnew.png"
import PhoneBanner from "../Assets/Features/PhoneBannernew.png"
import Nosetup from "../Assets/Features/nosetup.png"
import pickup from "../Assets/Features/pickup.png"
import api from "../Assets/Features/api.png"
import ShippingRateCalculator from "../Assets/Features/Shippingcalculatornew.png"
import RealTimeTracking from "../Assets/Features/RealTimeTracking.png"
import CustomerSupport from "../Assets/Features/ClientSupportnew.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet-async'

const NextArrow = ({ onClick }) => {
  return (
    <div
      className="slick-next slick-arrow bg-black  text-white rounded-full flex items-center justify-center"
      onClick={onClick}
      style={{ width: '40px', height: '40px', right: '-8px' }}
    >
      <i className="fas fa-chevron-right"></i>
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div
      className="slick-prev slick-arrow bg-black text-white rounded-full flex items-center justify-center"
      onClick={onClick}
      style={{ width: '40px', height: '40px', left: '10px', zIndex: '1' }}
    >
      <i className="fas fa-chevron-left"></i>
    </div>
  );
};


function Features() {


  const settings = {
    // dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4
  };

  const phoneSettings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dots: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  const data = [
    {
      image: easy,
      title: 'Easy Management',
      paragraph: 'Effortlessly oversee all your shipments with our user-friendly platform.'
    },
    {
      image: Notification,
      title: 'Branded Tracking Alerts',
      paragraph: 'Send custom tracking alerts to keep your customers informed about their shipments.'
    },
    {
      image: Nosetup,
      title: 'No Platform or Setup Fee',
      paragraph: 'Enjoy our services with zero upfront costs or hidden fees, making it easy to get started.'
    },
    {
      image: Dashboard,
      title: 'Multi-Functional Dashboard',
      paragraph: 'Manage your shipping operations with a comprehensive, feature-rich dashboard that simplifies your tasks.'
    },
    {
      image: pickup,
      title: 'Multiple Pickup Locations',
      paragraph: 'Choose from various pickup points to suit your convenience and enhance your shipping experience.'
    },
    {
      image: COD,
      title: 'COD Remittance',
      paragraph: 'Receive cash-on-delivery payments promptly and reliably, ensuring customer satisfaction.'
    },
    {
      image: payment,
      title: 'Multiple Payment Options',
      paragraph: 'Offer a variety of payment methods to your customers, catering to their preferences.'
    },
    {
      image: brandedtracking,
      title: 'Branded Tracking',
      paragraph: "Provide a personalized tracking page to enhance your brand's visibility and customer experience."
    },
    {
      image: NDR,
      title: 'NDR Management',
      paragraph: 'Efficiently handle non-delivery reports to minimize disruptions and improve service reliability.'
    },
    {
      image: api,
      title: 'API Integration',
      paragraph: 'Seamlessly integrate our services with your existing systems via API for enhanced efficiency.'
    }
  ];


  return (
    <React.Fragment>
      <div className="flex font-sans text-slate-900 flex-col h-full mt-20">
        <Helmet>
          <title>Couriero Features - Discover Our Features</title>
          <meta name="description" content="Discover Couriero's top-notch features! Partner with 15+ trusted courier services, use our advanced shipping rate calculator, and enjoy the best shipping rates for efficient and reliable parcel delivery." />
          <link rel="canonical" href="https://couriero.in/features" />
        </Helmet>
        <div className="w-full">
          <div className=" flex-row w-full hidden lg:block">
            <img src={Banner} alt="Feature Banner" title="Explore Our Features at Couriero" />
          </div>
          <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
            <div className="relative flex-row w-full block lg:hidden">
              <div>
                <img src={PhoneBanner} alt="Feature Banner for Phone" title="Discover Our Phone Features at Couriero" />
              </div>
              <div className="absolute gap-14 justify-center mt-[-24rem] flex flex-col items-center">
                <div className="flex gap-2 flex-col">
                  <h1 className=" flex text-[1.57rem] font-bold px-9 text-center ">Empowering Your Business With Powerful Features</h1>
                  <h3 className=" flex text-[0.9rem] text-slate-700 font-medium px-12 text-center ">Unleash your business's full potential with our top features</h3>
                </div>
                <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                  <button className=" text-lg font-sans  px-8 py-2 flex  rounded-md bg-gray-200 font-semibold  hover:border hover:shadow-sm  hover:shadow-white bg-opacity-50 hover:bg-white ">Get Started
                  </button>
                </a>
              </div>
            </div>
          </a>
          <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
            <div className="absolute mt-[-9rem] ml-16  pt-[-2rem]">
              <button className="lg:flex hidden text-2xl font-sans ml-2 px-8 py-2 rounded-md bg-gray-200 font-semibold  hover:border hover:shadow-sm  hover:shadow-white hover:bg-opacity-50 hover:bg-white ">Get Started
              </button>
            </div>
          </a>
        </div>
        <div className="flex 2xl:px-60 flex-col lg:px-28 px-7  w-full">
          <div className="flex mt-10  shadow-md lg:shadow-none gap-x-6  lg:flex-row flex-col w-full">
            <div className="lg:w-5/12 lg:p-0 p-10 flex items-center w-full h-full lg:justify-end lg:pr-5">
              <img
                alt="Couriero Partners Banner"
                title="Explore Our Partner Network at Couriero"
                className="w-full rounded-lg"
                src={CourieroPartners}
              />            </div>
            <div className="flex lg:py-12 lg:px-12 rounded-lg pb-4 justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl  cursor-pointer ">15+ Couriero Partner</h2>
              <h3 className="lg:flex lg:pt-2 px-3 lg:px-0 tracking-tight pt-2  text-justify">We offer you access to over 15 reliable courier partners, ensuring your packages are delivered promptly and safely. With our extensive network, you can choose the best courier for your shipping needs, whether it's for domestic or international delivery. Enjoy the flexibility and efficiency of a diverse range of shipping options tailored to your business requirements.</h3>
            </div>
          </div>
          <div className="flex mt-10 justify-center shadow-md lg:shadow-none items-center lg:flex-row flex-col w-full">
            <div className="lg:block hidden lg:py-12 lg:px-12 rounded-lg pt-4 justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl  cursor-pointer ">Shipping Rates Calculator</h2>
              <h3 className="flex pt-2   text-justify">Our shipping rates calculator allows you to instantly determine the cost of sending your packages. Simply input the necessary details, and you'll receive accurate pricing from various couriers. This tool helps you find the most cost-effective shipping solution, ensuring you save time and money on every shipment.</h3>
            </div>
            <div className="lg:w-5/12 lg:p-0 p-10 flex items-center w-full h-full lg:justify-end lg:pr-5 ">
              <img
                alt="Shipping Rate Calculator Banner"
                title="Calculate Your Shipping Rates Easily with Couriero"
                className="drop-shadow-md shadow-white pr-5"
                src={ShippingRateCalculator}
              />
            </div>
            <div className="lg:hidden block  rounded-lg py-4 mt-5 justify-center items-center      flex-col">
              <h2 className="flex font-bold  justify-center  text-2xl  lg:text-4xl  cursor-pointer ">Shipping Rate Calculator</h2>
              <h3 className="flex pt-2 px-3 tracking-tight   text-justify">Our shipping rates calculator allows you to instantly determine the cost of sending your packages. Simply input the necessary details, and you'll receive accurate pricing from various couriers. This tool helps you find the most cost-effective shipping solution, ensuring you save time and money on every shipment.</h3>
            </div>
          </div>
          <div className="flex mt-14  shadow-md lg:shadow-none gap-x-6  lg:flex-row flex-col w-full">
            <div className="lg:w-5/12 lg:p-0 p-10 flex items-center w-full h-full lg:justify-end lg:pr-5">
              <img
                alt="Best Shipping Rate"
                title="Discover the Best Shipping Rates with Couriero"
                className="w-full drop-shadow-xl rounded-lg"
                src={blog}
              />            </div>
            <div className="flex lg:py-12 lg:px-12 rounded-lg py-4 justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl  cursor-pointer ">Best shipping rates</h2>
              <h3 className="lg:flex lg:pt-2 px-3 lg:px-0 tracking-tight pt-2  text-justify">We provide you with the best shipping rates available, leveraging our partnerships with multiple couriers. Compare prices and choose the most economical option without compromising on service quality. Our competitive rates ensure you get the best value for your shipping needs, keeping your costs low and your customers happy.</h3>
            </div>
          </div>
        </div>
        <div className="flex 2xl:px-60 flex-col h-full px-10  lg:px-24 ">
          <div className="mt-20 mb-12    hidden lg:block  snap-center slider-container ">
            <Slider {...settings}>
              {data.map(d => (
                <div className="bg-white h-[19rem] w-full shadow-lg my-5 text-black ">
                  <div className="h-5/12  rounded-t-xl bg-blue-200 hover:bg-opacity-80   flex justify-center items-center">
                    <div className="flex my-7 rounded-full justify-center animate-fadeIn   shadow-xl  bg-opacity-80  bg-white">
                      <img
                        src={d.image}
                        alt={`Feature: ${d.title}`}
                        title={`Learn more about: ${d.title}`}
                        className="w-28 object-none h-28"
                      />                    </div>
                  </div>
                  <div className="flex h-7/12 flex-col justify-center items-center gap-1 px-2 pt-6">
                    <h3 className="text-base font-bold">{d.title}</h3>
                    <p className="flex text-sm   justify-center   text-center ">{d.paragraph}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          <div className="mt-20   mb-12 block lg:hidden  snap-center slider-container ">
            <Slider {...phoneSettings}>
              {data.map(d => (
                <div className="bg-white h-[19rem] w-full shadow-lg my-5 text-black ">
                  <div className="  h-5/12  rounded-t-xl bg-blue-200 hover:bg-opacity-80   flex justify-center items-center">
                    <div className="flex my-7 rounded-full justify-center animate-fadeIn   shadow-xl  bg-opacity-80  bg-white">
                      <img
                        src={d.image}
                        alt={`Feature: ${d.title}`}
                        title={`Learn more about: ${d.title}`}
                        className="w-28 object-none h-28"
                      />
                    </div>
                  </div>
                  <div className="flex h-7/12 flex-col justify-center items-center gap-1 px-2 pt-6">
                    <p className="text-base font-bold">{d.title}</p>
                    <p className="flex text-sm   justify-center   text-center">{d.paragraph}</p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className="flex  flex-row justify-center w-full pb-3 px-2">
          <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
            <div className="flex flex-row justify-center rounded-full shadow-lg bg-blue-900">
              <button className="flex  flex-row justify-center  px-6 uppercase py-4 bg-gradient-to-r text-transparent from-orange-500 via-yellow-300 to-sky-400 text-lg  lg:text-xl bg-clip-text  font-extrabold">Sign up to know more</button>
            </div>
          </a>
        </div>

        <div className="flex 2xl:px-60  flex-col pb-14 pt-7 px-7  lg:px-28 w-full">
          <div className="flex   justify-center shadow-md lg:shadow-none items-center lg:flex-row flex-col w-full">
            <div className="lg:block hidden lg:py-12 lg:px-12 rounded-lg  justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl  cursor-pointer  ">5+ channel integrations</h2>
              <h3 className="lg:flex hidden pt-2 text-justify">Our service seamlessly integrates with over five leading marketplaces, streamlining your order processing and fulfillment. Connect your store with our platform to automate shipping tasks, manage orders efficiently, and provide a smooth shopping experience for your customers. Simplify your workflow with our powerful integrations.</h3>
            </div>
            <div className="lg:w-5/12 lg:p-0 p-10 flex items-center w-full h-full lg:justify-end lg:pr-5">
              <img
                alt="Channel Integration Banner"
                title="Explore our Channel Integration feature"
                className="w-full drop-shadow-lg rounded-lg"
                src={ChannelIntegration}
              />
            </div>
            <div className="lg:hidden block  rounded-lg pt-8 pb-2 justify-center items-center      flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl justify-center  cursor-pointer ">5+ channel integrations</h2>
              <h3 className="flex pt-2 px-3 tracking-tight   text-justify">Our service seamlessly integrates with over five leading marketplaces, streamlining your order processing and fulfillment. Connect your store with our platform to automate shipping tasks, manage orders efficiently, and provide a smooth shopping experience for your customers. Simplify your workflow with our powerful integrations.</h3>
            </div>
          </div>
          <div className="flex mt-10  shadow-md lg:shadow-none gap-x-6  lg:flex-row flex-col w-full">
            <div className="lg:w-5/12 lg:p-0 p-10 flex items-center w-full h-full lg:justify-end ">
              <img
                alt="Customer Support"
                title="Exceptional Customer Support Available"
                className="w-full p-5 lg:pr-0"
                src={CustomerSupport}
              />
            </div>
            <div className="flex lg:py-12  lg:px-12 rounded-lg pb-4 justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold    text-2xl  lg:text-4xl  cursor-pointer">Client support</h2>
              <h3 className="lg:flex lg:pt-2 px-3 lg:px-0 tracking-tight pt-2  text-justify">We are dedicated to providing exceptional client support. Our knowledgeable team is available to assist you with any queries or issues you may encounter. Whether you need help with shipping logistics, integration, or troubleshooting, our support ensures a smooth and efficient experience. We are here to support your business every step of the way.</h3>
            </div>
          </div>
          <div className="flex mt-12  justify-center shadow-md lg:shadow-none items-center lg:flex-row flex-col w-full">
            <div className="lg:block hidden lg:py-12  lg:px-12 rounded-lg pt-6 justify-center items-center    lg:items-start  lg:w-7/12 flex-col">
              <h2 className="flex font-bold   text-2xl  lg:text-4xl  cursor-pointer">Real-time tracking updates</h2>
              <h3 className="lg:flex hidden pt-2 text-justify">Stay informed with our real-time tracking updates. Monitor your shipments from dispatch to delivery, ensuring you and your customers always know the status of their packages. Our tracking system provides accurate and timely information, enhancing transparency and customer satisfaction.</h3>
            </div>
            <div className="lg:w-5/12 flex p-4 items-center w-full h-full lg:justify-end lg:pr-5">
              <img
                alt="Real-Time Tracking Feature"
                title="Real-Time Tracking for Shipments"
                className="w-full p-2"
                src={RealTimeTracking}
              />
            </div>
            <div className="lg:hidden block mt-5 rounded-lg py-4 justify-center items-center      flex-col">
              <h2 className="flex font-bold justify-center    text-2xl  lg:text-4xl  cursor-pointer ">Real-time tracking updates</h2>
              <h3 className="lg:flex lg:pt-2 px-3 lg:px-0 tracking-tight pt-2  text-justify">Stay informed with our real-time tracking updates. Monitor your shipments from dispatch to delivery, ensuring you and your customers always know the status of their packages. Our tracking system provides accurate and timely information, enhancing transparency and customer satisfaction.</h3>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Features;